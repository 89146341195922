import React, { Component } from 'react'
import { Dropdown }  from './Library'


export class CountryOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = key;
	}
}

export class Business extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessName: '',
			addressLine1: '',
			addressLine2: '',
			town: '',
			county: '',
			postcode: '',
			countryId: null,
			emailAddress: '',
			phoneNumber: '',
			iconPath: '',
			parentBusinessId: '',
			businessOwnId: '',
			countrys: [],
			iconFile: null,
			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getCountryData();

	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = "countryId";
		this.setState({
			[name]: e.target.value
		})
	}

	handleCountryDropDownChange = (e) => {
		this.setState({
			"countryId" : e.target.value
        })
    }

	handleFileChange = (event) => {
		this.setState({
			iconFile: event.target.files[0]
        })
    }

	handleSubmit = (event) => {

		const formData = new FormData();
		formData.append("businessName", this.state.businessName);
		formData.append("addressLine1", this.state.addressLine1);
		formData.append("addressLine2", this.state.addressLine2);
		formData.append("town", this.state.town);
		formData.append("county", this.state.county);
		formData.append("postcode", this.state.postcode);
		formData.append("countryId", this.state.countryId);
		formData.append("emailAddress", this.state.emailAddress);
		formData.append("phoneNumber", this.state.phoneNumber);
		formData.append("iconPath", this.state.iconPath);
		formData.append("parentBusinessId", this.state.parentBusinessId);
		formData.append("businessOwnId", this.state.businessOwnId);
		formData.append("iconFile", this.state.iconFile);

		fetch(process.env.REACT_APP_API_ENDPOINT + 'business', {
			method: 'POST',
			body: formData
			}).then(function(response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}

	async getCountryData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'country');
		const data = await response.json();
		this.setState({ countrys: data.map((country) => new CountryOption(country.countryId, country.countryName, country.countryName)), loading: false });
}

	DisplayCountryDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.countrys}
			onChange={this.handleCountryDropDownChange}
			value={value}
		/>
		);
	}



	render() {
		let countryDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayCountryDropdown();

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					BusinessName:
					<input type="text" name="businessName" value={this.state.businessName} onChange={this.handleInputChange} />
				</label>

				<label>
					AddressLine1:
					<input type="text" name="addressLine1" value={this.state.addressLine1} onChange={this.handleInputChange} />
				</label>

				<label>
					AddressLine2:
					<input type="text" name="addressLine2" value={this.state.addressLine2} onChange={this.handleInputChange} />
				</label>

				<label>
					Town:
					<input type="text" name="town" value={this.state.town} onChange={this.handleInputChange} />
				</label>

				<label>
					County:
					<input type="text" name="county" value={this.state.county} onChange={this.handleInputChange} />
				</label>

				<label>
					Postcode:
					<input type="text" name="postcode" value={this.state.postcode} onChange={this.handleInputChange} />
				</label>

				<label>
					Country
					{countryDropdown}
				</label>

				<label>
					EmailAddress:
					<input type="text" name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} />
				</label>

				<label>
					PhoneNumber:
					<input type="text" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleInputChange} />
				</label>

				<label>
					IconPath:
					<input type="text" name="iconPath" value={this.state.iconPath} onChange={this.handleInputChange} />
				</label>

				<label>
					ParentBusinessId:
					<input type="text" name="parentBusinessId" value={this.state.parentBusinessId} onChange={this.handleInputChange} />
				</label>

				<label>
					BusinessOwnID:
					<input type="text" name="businessOwnID" value={this.state.businessOwnId} onChange={this.handleInputChange} />
				</label>

				<label>
					Receipt Icon:
					<input type="file" name="iconFile" onChange={this.handleFileChange} />
					<div>
						{this.state.iconFile != null ? (
						<><p>Filename: {this.state.iconFile.name}</p><p>Filetype: {this.state.iconFile.type}</p><p>Size in bytes: {this.state.iconFile.size}</p>
                                <p>
								lastModifiedDate:{' '}
                                    {this.state.iconFile.lastModifiedDate.toLocaleDateString()}
                                </p></>
						) : (<p>Please select a file</p>)}
					</div>
					</label>
						


				<input type="submit" value="Save" />
			</form>

			
		);
	}
}
